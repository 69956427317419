import React from "react"
import IntroSection from "./intro-section"
import PostsSection from "./posts-section"
import ContactSection from "../../common/contact/contact-section"
import Theme from "../../../theme/theme"

const BlogContent = ({ pageContext }) => {
  const blogData = pageContext.blogPage
  const blogPosts = pageContext.blogPosts
  const blogPageSlug = blogData.slug

  return (
    <>
      <IntroSection
        blogData={blogData}
        featuredPost={blogPosts[0]}
        blogPageSlug={blogPageSlug}
      />
      {pageContext.blogPosts.length > 1 && (
        <PostsSection
          posts={blogPosts}
          blogPageSlug={blogPageSlug}
          categories={pageContext.categories}
        />
      )}
      <ContactSection
        content={blogData.contact}
        sectionColor={Theme.colors.gray2}
        contactPageSlug={pageContext.contactPageSlug}
      />
    </>
  )
}

export default BlogContent
