import React from "react"
import Viewport from "../../components/common/viewport"
import BlogContent from "../content/blog/blog-content"
import SEO from "../seo"

const Blog = props => {
  const page = props.pageContext.blogPage

  if (!page) return

  return (
    <Viewport>
      <BlogContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default Blog

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.blogPage.seoTitle}
    description={pageContext.blogPage.seoDescription}
  />
)
