import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Section from "../../common/section"
import PostsList from "../../common/blog/posts-list"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 8rem;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    padding-top: 18rem;
  }
`

const PostsSection = ({ posts, categories, blogPageSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
      sectionId="posts-section"
    >
      <PostsList
        posts={posts.slice(1, posts.length)}
        showFilters={true}
        categories={categories}
        blogPageSlug={blogPageSlug}
      />
    </StyledSection>
  )
}

PostsSection.propTypes = {
  posts: PropTypes.array,
  categories: PropTypes.array,
  blogPageSlug: PropTypes.string,
}

export default PostsSection
