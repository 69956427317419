import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import Shape from "../../svg/shape"
import GatsbyImage from "../../common/gatsby-image"
import SinglePost from "../../common/blog/single-post"
import ScrollButton from "../../common/scroll-button"
import { TRANSLATIONS } from "../../../utils/translations"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 18rem;

  @media (max-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 0;
  }
`

const IntroContent = styled.div`
  position: relative;
  padding-top: 11rem;

  h1,
  h2 {
    max-width: 95rem;
    font-size: 4rem;
    line-height: 4.5rem;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 8rem;
      line-height: 8rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  z-index: 1;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 37.4rem;
  right: 13.9rem;
  width: 20rem;
  height: 20rem;
  transform: rotate(-90deg);
  z-index: 1;
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  top: 0;
  right: 40rem;
  width: 80rem;
  height: 80rem;
  opacity: 0.4;
`

const FeaturedPost = styled.div`
  position: relative;

  article {
    max-width: 70rem;
    margin-top: 5rem;

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      max-width: 110rem;
      margin-top: 9rem;
    }

    h4 {
      margin-top: 1.5rem;
      margin-bottom: 4rem;
    }
  }
`

const GatsbyImageContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 50%;
  right: 0;
  max-width: 70rem;
  border-radius: 40rem;
  transform: translate3d(0, -50%, 0);

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    max-width: 120rem;
  }

  > div {
    opacity: 0.3;
  }
`

const StyledScrollButton = styled(ScrollButton)`
  position: relative;
  max-width: 32rem;
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-family: "darker_grotesquebold", sans-serif;
  color: inherit;
  text-align: left;
  background: none;
  border: 0;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: 9rem;
  padding-right: 12rem;
  cursor: pointer;

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    margin-top: 20rem;
    margin-left: auto;
    margin-right: 9rem;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -4rem;
  }
`

const IntroSection = ({ blogData, featuredPost, blogPageSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
    >
      <IntroContent>
        {documentToReactComponents(JSON.parse(blogData.introText.raw))}

        {featuredPost && (
          <FeaturedPost>
            <SinglePost post={featuredPost.node} blogPageSlug={blogPageSlug} />
            {featuredPost.node.mainImage && (
              <GatsbyImageContainer>
                <GatsbyImage
                  image={featuredPost.node.mainImage.gatsbyImageData}
                  isAnimated={true}
                />
                <StyledShape1 isMainColor={true} type={9} />
                <StyledShape2 isSecondColor={true} />
                <StyledShape3 type={8} isSecondColor={true} />
              </GatsbyImageContainer>
            )}
          </FeaturedPost>
        )}
        <StyledScrollButton sectionId="posts-section">
          {TRANSLATIONS[process.env.GATSBY_CONTENTFUL_LOCALE].seeMorePosts}
        </StyledScrollButton>
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  blogData: PropTypes.object,
  featuredPost: PropTypes.object,
  blogPageSlug: PropTypes.string,
}

export default IntroSection
